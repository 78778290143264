import { ListingDetailsType } from "@utils/ListingTypes"
import React from "react"
import {Formik}  from 'formik';
import * as Yup from 'yup';
import { Form, Grid } from "semantic-ui-react";
import { KpFormField, KpFormInputErrorMessage } from "@styles/Global.forms";
import KpButton from "@elements/KpButton/KpButton";
import { Paragraph1 } from "@styles/Global.styles";
import theme from "@styles/theme";
import { MarketplaceListingService } from "@services/MarketplaceListingService";
import { PersonalDetailsTitleDropdown } from "@components/PersonalDetailsForm/PersonalDetailsForm.styles";
import { ListingEnquiryFormColumn } from "@components/ListingDetailsBanner/ListingDetailsBanner.styles";
import { htmlEncode } from "@utils/Helpers";
import { EmailRegex, NameRegex } from "@utils/Constant";

interface ReportListingFormProps {
    listingId: number,
    apiUrl: string
}

const ReportListingForm  = (props: ReportListingFormProps) => {
    const formSettings = {
        intialStatus: {
          isSubmitting: false,
          isSubmitted: false
        },
        initialValues: {
          name: '',
          email: '',
          message: '',
          reason: 'This plate offends me'
        },
        validationSchema: Yup.object({
          name: Yup.string()
              .required('Name is required')
              .matches(NameRegex, "Enter valid characters"),
          email: Yup.string()
              .required('Email is required')
              .matches(EmailRegex ,'Must enter a valid email address')           
        })
      };
    
      const submitForm = (values: any, actions: any) => {
          actions.setStatus({
              isSubmitting: true
          });
    
          MarketplaceListingService.reportListing(props.apiUrl, props.listingId, values.name, values.email, values.reason, htmlEncode(values.message)).then((data: any) => {
            if(data.data.Success){
                actions.setStatus({
                    isSubmitting: false,
                    isSubmitted: true
                });
            }
          })
      }  
    
      return (
        <Formik initialValues={formSettings.initialValues} validationSchema={formSettings.validationSchema} initialStatus={formSettings.intialStatus} onSubmit={(values: any, actions: any) => submitForm(values, actions)}>
            { props => (
                <Form onSubmit={props.handleSubmit}>
                    <Grid columns={16}>
                        <Grid.Row>
                            {
                                !props.status.isSubmitted && (
                                    <Grid.Column mobile={16} tablet={16} computer={14}>
                                        <Grid width={14}>
                                            <ListingEnquiryFormColumn mobile={16} tablet={8} computer={8}>
                                                <Form.Group>
                                                    <KpFormField required error={props.errors.name && props.touched.name}>
                                                        <label>Name</label>
                                                        {
                                                            props.errors.name && props.touched.name && (
                                                                <KpFormInputErrorMessage>
                                                                    {props.errors.name}
                                                                </KpFormInputErrorMessage>
                                                            )
                                                        }
                                                        <input
                                                            id='name'
                                                            name='name'
                                                            data-private="lipsum"
                                                            type='text'            
                                                            aria-label='Name'                                                                
                                                            disabled={props.status.isSubmitting}
                                                            placeholder='Name'
                                                            onChange={props.handleChange}
                                                            onBlur={props.handleBlur}
                                                            value={props.values.name}>                                                                                
                                                        </input>                                                                                                                                                
                                                    </KpFormField>
                                                </Form.Group>
                                            </ListingEnquiryFormColumn>
                                            <ListingEnquiryFormColumn mobile={16} tablet={8} computer={8}>
                                                <Form.Group>
                                                    <KpFormField required error={props.errors.email && props.touched.email}>
                                                        <label>Email</label>
                                                        {
                                                            props.errors.email && props.touched.email && (
                                                                <KpFormInputErrorMessage>
                                                                    {props.errors.email}
                                                                </KpFormInputErrorMessage>
                                                            )
                                                        }
                                                        <input
                                                            id='email'
                                                            name='email'
                                                            data-private="lipsum"
                                                            aria-label='Email Address'
                                                            type='text'                                                                            
                                                            disabled={props.status.isSubmitting}
                                                            placeholder='Email'
                                                            onChange={props.handleChange}
                                                            onBlur={props.handleBlur}
                                                            value={props.values.email}>                                                                                
                                                        </input>                                                                                                                                                
                                                    </KpFormField>
                                                </Form.Group>
                                            </ListingEnquiryFormColumn>   
                                            <ListingEnquiryFormColumn mobile={16} tablet={16} computer={16}>
                                                <Form.Group>
                                                    <KpFormField>
                                                        <label>Reason</label>
                                                        <PersonalDetailsTitleDropdown
                                                            fluid
                                                            selection
                                                            options={[
                                                            {
                                                                text: "This plate offends me",
                                                                value: "This plate offends me",
                                                            },
                                                            {
                                                                text: "This plate contains profanity",
                                                                value: "This plate contains profanity",
                                                            },
                                                            {
                                                                text: "Other",
                                                                value: "Other",
                                                            }
                                                            ]}
                                                            icon="chevron down"
                                                            id="applicantDetails.title"
                                                            name="applicantDetails.title"
                                                            disabled={props.status.isSubmitting}
                                                            value={props.values.reason}
                                                            onBlur={props.handleBlur}
                                                            onChange={(el: any, data: any) => {
                                                            props.setFieldValue(
                                                                "reason",
                                                                data.value
                                                            )
                                                            }}
                                                        />
                                                    </KpFormField>
                                                </Form.Group>
                                            </ListingEnquiryFormColumn>                                      
                                            <ListingEnquiryFormColumn mobile={16} tablet={16} computer={16}>
                                                <Form.Group>
                                                    <KpFormField error={props.errors.message && props.touched.message}>
                                                        <label>Message</label>
                                                        {
                                                            props.errors.message && props.touched.message && (
                                                                <KpFormInputErrorMessage>
                                                                    {props.errors.message}
                                                                </KpFormInputErrorMessage>
                                                            )
                                                        }
                                                        <textarea
                                                            id='message'
                                                            name='message'
                                                            aria-label="Message"                                                                     
                                                            disabled={props.status.isSubmitting}
                                                            placeholder='Message'
                                                            onChange={props.handleChange}
                                                            onBlur={props.handleBlur}
                                                            value={props.values.message}>                                                                                
                                                        </textarea>                                                                                                                                                
                                                    </KpFormField>
                                                </Form.Group>
                                            </ListingEnquiryFormColumn>
                                            <Grid.Column mobile={16} tablet={16} computer={16}>
                                                <KpButton id="cub-send" type='submit' loading={props.status.isSubmitting} buttonType='primary' color={theme.brand.colors.blue}>REPORT LISTING</KpButton>
                                            </Grid.Column>
                                        </Grid>
                                    </Grid.Column>
                                )
                            }
                            {
                                props.status.isSubmitted && (
                                    <Grid.Column verticalAlign='middle' width={16}>
                                        <Paragraph1 color={theme.brand.colors.black}>
                                            Your enquiry has been successfully sent, we will review the details of this listing shortly.
                                        </Paragraph1>
                                    </Grid.Column>
                                )
                            }
                        </Grid.Row>
                    </Grid>
                </Form>
            )}
        </Formik>
      )
}

export default ReportListingForm;