import React from "react"
import { graphql } from "gatsby"
import { Layout } from "@components/Layout"
import { SignupBannerWrapper } from "@components/SignupBanner"
import ReportListingForm from "@components/ReportListing/ReportListingForm"
import { Grid, Icon } from "semantic-ui-react"
import { GenericBannerContainer } from "@components/GenericBannerContainer"
import { BackLink, BackLinkContainer } from "@components/PlateDesignImagesContainer/PlateDesignImagesContainer.styles"
import { Hyperlink } from "@elements/index"
import { H3, H4 } from "@styles/Global.styles"
import theme from "@styles/theme"

const queryString = require('query-string');

const ReportListingPage = (props: any) => {
  const apiUrl = props.data.site.siteMetadata.apiUrl;
  const query = queryString.parse(props.location.search);
  const { elements } = props.data.kontentItemListingItemPage;
  const kiwiVipVisible = elements.kiwivip_signup.value[0].codename;
  const kiwiVipVersion = elements.kiwivip_signup_version.value[0].codename;

  return (<Layout location={props.location} version="simple" authEnabled={true}>
    <GenericBannerContainer padding={{
      mobile: {
        top: 60,
        bottom: 60
      },
      desktop: {
        top: 220,
        bottom: 80
      }
    }
    }
      backgroundColor={theme.brand.colors.beige}>
      <Grid centered>
        <Grid.Row centered>
          <Grid.Column mobile={16} tablet={10} computer={8}>
            <BackLinkContainer>
              <Hyperlink url={`/listing-detail?listingId=${query.listingId}`}>
                <BackLink>
                  <Icon name="chevron left" size="large" /> BACK TO LISTING
              </BackLink>
              </Hyperlink>
            </BackLinkContainer>
            <H3>Report Listing</H3>
            <br></br>
            <ReportListingForm listingId={query.listingId} apiUrl={apiUrl} />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </GenericBannerContainer>
    {
      kiwiVipVisible == "visible" && (
        <SignupBannerWrapper version={kiwiVipVersion} />
      )
    }
  </Layout>
  )
}

export const query = graphql`
  {
    site {
      siteMetadata {
        apiUrl
      }
    }
    kontentItemListingItemPage {
      elements {
        kiwivip_signup {
          value {
            codename
          }
        }
        seo__noindex {
          value {
            codename
          }
        }
        seo__page_description {
          value
        }
        seo__nofollow {
          value {
            codename
          }
        }
        title {
          value
        }
        seo__page_title {
          value
        }
        kiwivip_signup_version {
          value {
            codename
          }
        }
      }
    }
  }
`
export default ReportListingPage
